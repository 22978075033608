<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

<table [hidden]="deleteSuccess" class="table table-striped">
  <tr>
    <td valign="top">
      <dl class="dl-horizontal">
        <dt>Name</dt>
        <dd>{{ pet.name }}</dd>
        <dt>Birth Date</dt>
        <dd>{{ pet.birthDate }}</dd>
        <dt>Type</dt>
        <dd *ngIf="!deleteSuccess">{{ pet.type.name }}</dd>
        <button class="btn btn-default" (click)="editPet(pet)">Edit Pet</button>
        <button class="btn btn-default" (click)="deletePet(pet)">Delete Pet</button>
        <button class="btn btn-default" (click)="addVisit(pet)">Add Visit</button>

      </dl>
    </td>
    <td valign="top">
      <app-visit-list [visits]="pet.visits"></app-visit-list>
    </td>
  </tr>

</table>
