/*
 *
 *  * Copyright 2016-2017 the original author or authors.
 *  *
 *  * Licensed under the Apache License, Version 2.0 (the "License");
 *  * you may not use this file except in compliance with the License.
 *  * You may obtain a copy of the License at
 *  *
 *  *      http://www.apache.org/licenses/LICENSE-2.0
 *  *
 *  * Unless required by applicable law or agreed to in writing, software
 *  * distributed under the License is distributed on an "AS IS" BASIS,
 *  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  * See the License for the specific language governing permissions and
 *  * limitations under the License.
 *
 */

/**
 * @author Vitaliy Fedoriv
 */

import {RouterModule, Routes} from '@angular/router';
import {PetListComponent} from './pet-list/pet-list.component';
import {NgModule} from '@angular/core';
import {VisitAddComponent} from '../visits/visit-add/visit-add.component';
import {PetEditComponent} from './pet-edit/pet-edit.component';
import {PetAddComponent} from './pet-add/pet-add.component';


const petRoutes: Routes = [
  {path: 'pets', component: PetListComponent},
  {path: 'pets/add', component: PetAddComponent},
  {
    path: 'pets/:id',
    children: [
      {
        path: 'edit',
        component: PetEditComponent
      },
      {
        path: 'visits\/add',
        component: VisitAddComponent
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(petRoutes)],
  exports: [RouterModule]
})

export class PetsRoutingModule {
}
