<!--
  ~ /*
  ~  * Copyright 2016-2017 the original author or authors.
  ~  *
  ~  * Licensed under the Apache License, Version 2.0 (the "License");
  ~  * you may not use this file except in compliance with the License.
  ~  * You may obtain a copy of the License at
  ~  *
  ~  *      http://www.apache.org/licenses/LICENSE-2.0
  ~  *
  ~  * Unless required by applicable law or agreed to in writing, software
  ~  * distributed under the License is distributed on an "AS IS" BASIS,
  ~  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  ~  * See the License for the specific language governing permissions and
  ~  * limitations under the License.
  ~  */
  -->

  <div class="container-fluid">
    <div class="container xd-container">
      <h2>New Pet Type</h2>
      <form id="pettype" class="form-horizontal" (ngSubmit)="onSubmit(pettypeForm.value)" #pettypeForm="ngForm">
        <div class="form-group" hidden="true">
          <input type="text" hidden="true" class="form-control" id="id" [(ngModel)]="pettype.id" name="id"/>
        </div>
        <div class="form-group has-feedback"  [class.has-success]="name.dirty && name.valid" [class.has-error]="name.dirty &&  !name.valid">
          <div class="form-group ">
            <label class="col-sm-1 control-label">Name</label>
            <div class="col-sm-6">
              <input id="name" name="name" class="form-control" minlength="1" maxlength="80" pattern="^[A-Za-z0-9].{0,79}$" required type="text" [(ngModel)]="pettype.name" #name="ngModel"/>
              <span class="glyphicon form-control-feedback" [class.glyphicon-ok]="name.valid" [class.glyphicon-remove]="!name.valid" aria-hidden="true"></span>
              <span class="help-block" *ngIf="name.dirty && name.hasError('maxlength')">Name may be only 80 characters long</span>
              <span class="help-block" *ngIf="name.dirty && name.hasError('minlength')">Name may be at least 1 characters long</span>
              <span class="help-block" *ngIf="name.dirty && name.hasError('pattern')">Name must begin with a letter or digit</span>
               <span class="help-block" *ngIf="(pettypeForm.submitted && name.hasError('required')) || (name.dirty && name.hasError('required'))">Name is required</span>
            </div>
            <button class="btn btn-default" type="submit" [disabled]="!pettypeForm.valid">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  